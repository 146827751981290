/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule, getLocale } from '@libs/dash/core';
import { FeatureMapService } from '@libs/dash/guards';
import { PageAsideNavigationModule } from '@libs/dash/shared';
import { CommonLayoutModule } from '@libs/shared/modules/common-layout';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CoreModule,
		AppRoutingModule,
		TranslocoRootModule,
		PageAsideNavigationModule,
		MatTableModule,
		MatExpansionModule,
		MatIconModule
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [FeatureMapService],
			useFactory: (fm: FeatureMapService) => {
				return () => {
					return fm.loadFeatureMap();
				};
			},
		},
		{ provide: MAT_DATE_LOCALE, useValue: getLocale() },
		{ provide: LOCALE_ID, useValue: getLocale() },
	],
})
export class AppModule { }
