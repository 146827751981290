/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationModuleSections, DEFAULT_INITIAL_ROUTE, REDIRECT_URL } from '@libs/dash/core/entity';
import { RedirectComponent } from '@libs/dash/features/v1';
import { AuthGuard, FeatureGuard, PermissionAccountingGuard, PermissionGuard } from '@libs/dash/guards';

const routes: Routes = [
	{
		path: `${ApplicationModuleSections.ACCOUNT}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.AccountModule),
	},
	{
		path: `${ApplicationModuleSections.GLOBAL_OVERVIEW}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.GlobalOverviewModule),
		canActivate: [AuthGuard, PermissionGuard],
	},
	{
		path: `${ApplicationModuleSections.SALES_REPORTS}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.ReportsModule),
		canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'reports' },
	},
	{
		path: `${ApplicationModuleSections.DETAIL}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DashboardItemDetailModule),
		canActivate: [AuthGuard, PermissionGuard],
	},

	{
		path: `${ApplicationModuleSections.SETTINGS}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.SettingsModule),
		canActivate: [AuthGuard, PermissionGuard],
	},
	{
		path: `${ApplicationModuleSections.HISTORY_LOGS}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.HistoryLogsModule),
		canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'history' },
	},
	{
		path: `${ApplicationModuleSections.DTT_EXPORT}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DttExportModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'dtt' },
	},
	{
		path: `${ApplicationModuleSections.TOS}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.TimeOfServiceModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'tos' },
	},
	{
		path: `${ApplicationModuleSections.ACCOUNTING}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.AccountingModule),
		canActivate: [AuthGuard, PermissionAccountingGuard],
		data: { canActivateFeatureFlag: 'accounting' },
	},
	{
		path: `${ApplicationModuleSections.PERFORMANCE}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.PerformanceModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'performance' },
	},
	{
		path: `${ApplicationModuleSections.DTT_EXPORT_SOURCED}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DttExportModule),
		canActivate: [],
		data: { canActivateFeatureFlag: 'dtt' },
	},
	{
		path: `${ApplicationModuleSections.PERFORMANCE_SOURCED}`,
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.PerformanceModule),
		canActivate: [],
		data: { canActivateFeatureFlag: 'performance' },
	},
	{
		path: `${ApplicationModuleSections.ERROR}`,
		loadChildren: () => import('@libs/dash/shared').then((m) => m.ErrorModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'v2',
		loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DashAppContentModule),
		canActivate: [AuthGuard],
	},
	{
		path: `${REDIRECT_URL}`,
		component: RedirectComponent,
	},
	{
		path: '',
		redirectTo: DEFAULT_INITIAL_ROUTE,
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: DEFAULT_INITIAL_ROUTE,
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
