/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { CustomIconRegistryService } from '@libs/dash/core';

@Component({
	selector: 'dk-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(crs: CustomIconRegistryService, meta: Meta) {
		crs.registerInitialCustomIcons();

		meta.addTag({ name: 'theme-color', content: '#bb1e32' });
		meta.addTag({ name: 'msapplication-TileColor', content: '#ffffff' });
	}
}
