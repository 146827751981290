/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE_LANGUAGE_KEY, FALLBACK_LANGUAGE_KEY } from '@libs/dash/core/entity';
import { provideTransloco, TRANSLOCO_LOADER, TranslocoModule } from '@ngneat/transloco';

import { environment } from '../../environments/environment';
import { TranslocoHttpLoader } from './transloco-http-loader.service';

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideHttpClient(),
		provideTransloco({
			config: {
				availableLangs: AVAILABLE_LANGUAGES,
				defaultLang: DEFAULT_LOCALE_LANGUAGE_KEY,
				fallbackLang: FALLBACK_LANGUAGE_KEY,
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader,
		}),

		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
	],
})
export class TranslocoRootModule {}
